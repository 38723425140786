import React, { Suspense } from 'react';
import './App.css';
import './resources/main.scss';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from "antd";
import './resources/main.scss';
import { loginService } from '../src/services/LoginService.jsx';
const Login = React.lazy(() => import('./containers/Login/Login'));
const SignUp = React.lazy(() => import('./containers/SignUp/SignUp'));
const Events = React.lazy(() => import('./containers/Admin/Events/Events'));
const TodoList = React.lazy(() => import('./containers/Admin/TodoList/TodoList'));
const Budgets = React.lazy(() => import('./containers/Admin/Budgets/Budgets'));
const MyTasks = React.lazy(() => import('./containers/Admin/MyTasks/MyTasks'));
const SingleBudget = React.lazy(() => import('./containers/Admin/SingleBudget/SingleBudget'));
const SignUpStep2 = React.lazy(() => import('./containers/SignUpStep2/SignUpStep2'));
const WebsitesList = React.lazy(() => import('./containers/Admin/WebsitesList/WebsitesList'));
const Website = React.lazy(() => import('./containers/Admin/Website/Website'));
const Questionnaires = React.lazy(() => import('./containers/Admin/Questionnaires/Questionnaires'));
const Questions = React.lazy(() => import('./containers/Admin/Questions/Questions'));
const QuestionsAnswers = React.lazy(() => import('./containers/Admin/QuestionsAnswers/QuestionsAnswers'));
const Activities = React.lazy(() => import('./containers/Admin/Activities/Activities'));
const ContactPeople = React.lazy(() => import('./containers/Admin/ContactPeople/ContactPeople'));
const Speakers = React.lazy(() => import('./containers/Admin/Speakers/Speakers'));
const Guests = React.lazy(() => import('./containers/Admin/Guests/Guests'));
const PreviewWebsite = React.lazy(() => import('./containers/Admin/PreviewWebsite/PreviewWebsite'));
const Dashboard = React.lazy(() => import('./containers/Admin/Dashboard/Dashboard'));
const Profile = React.lazy(() => import('./containers/Profile/Profile'));
const Answers = React.lazy(() => import('./containers/Admin/Answers/Answers'));
const Company = React.lazy(() => import('./containers/Admin/Company/Company'));
const PreviewTemplate = React.lazy(() => import('./containers/Admin/PreviewTemplate/PreviewTemplate'));
const EventWebsite = React.lazy(() => import('./containers/EventWebsite/EventWebsite'));
const Homepage = React.lazy(() => import('./containers/Homepage/Homepage'));
const Notifications = React.lazy(() => import('./containers/Admin/Notifications/Notifications'));
const MyEvents = React.lazy(() => import('./containers/Admin/MyEvents/MyEvents'));
const ResetPassword = React.lazy(() => import('./containers/ResetPassword/ResetPassword'));
const UserProfile = React.lazy(() => import('./containers/UserAdmin/UserProfile'));
const Agenda = React.lazy(() => import('./containers/Admin/Agenda/Agenda'));
const CheckIn = React.lazy(() => import('./containers/Admin/CheckIn/CheckIn'));
const Raports = React.lazy(() => import('./containers/Admin/Raports/Raports'));
const AgendaActivities = React.lazy(() => import('./containers/Admin/AgendaActivities/AgendaActivities'));
const Partners = React.lazy(() => import('./containers/Admin/Partners/Partners'));
const UserCheckIn = React.lazy(() => import('./containers/UserCheckIn/UserCheckIn'));

function App() {
  return (
      <div className="App">
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Raleway'
            }
          }}
        >
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/" element={<Homepage />} />
              <Route exact path="/creeaza-cont" element={<SignUp />} />
              <Route exact path="/creeaza-cont-2" element={<SignUpStep2 />} />
              <Route exact path="/reseteaza-parola" element={<ResetPassword />} />
              <Route exact path="/evenimente" element={<AdminRoute><Events /></AdminRoute>} />
              <Route exact path="/lista-taskuri" element={<AdminRoute><TodoList /></AdminRoute>} />
              <Route exact path="/bugete" element={<AdminRoute><Budgets /></AdminRoute>} />
              <Route exact path="/taskurile-mele" element={<AdminRoute><MyTasks /></AdminRoute>} />
              <Route exact path="/buget/:id" element={<AdminRoute><SingleBudget /></AdminRoute>} />
              <Route exact path="/companie" element={<AdminRoute><Company /></AdminRoute>} />
              <Route exact path="/agenda" element={<AdminRoute><Agenda /></AdminRoute>} />
              <Route exact path="/agenda/:id" element={<AdminRoute><AgendaActivities /></AdminRoute>} />
              <Route exact path="/raspunsuri" element={<AdminRoute><Answers /></AdminRoute>} />
              <Route exact path="/website-builder" element={<AdminRoute><WebsitesList /></AdminRoute>} />
              <Route exact path="/chestionare" element={<AdminRoute><Questionnaires /></AdminRoute>} />
              <Route exact path="/chestionare/:id/intrebari" element={<AdminRoute><Questions /></AdminRoute>} />
              <Route exact path="/chestionare/:id/raspunsuri" element={<AdminRoute><QuestionsAnswers /></AdminRoute>} />

              <Route exact path="/rapoarte" element={<AdminRoute><Raports /></AdminRoute>} />
              <Route exact path="/activitati" element={<AdminRoute><Activities /></AdminRoute>} />
              <Route exact path="/check-in" element={<AdminRoute><CheckIn /></AdminRoute>} />
              <Route exact path="/persoane-de-contact" element={<AdminRoute><ContactPeople /></AdminRoute>} />
              <Route exact path="/website/:id" element={<AdminRoute><Website /></AdminRoute>} />
              <Route exact path="/invitati" element={<AdminRoute><Guests /></AdminRoute>} />
              <Route exact path="/dashboard" element={<AdminRoute><Dashboard /></AdminRoute>} />
              <Route exact path="/preview-website/:id" element={<AdminRoute><PreviewWebsite /></AdminRoute>} />
              <Route exact path="/preview-website/:id/*" element={<AdminRoute><PreviewWebsite /></AdminRoute>} />
              <Route exact path="/preview-template/:id/*" element={<AdminRoute><PreviewTemplate /></AdminRoute>} />
              <Route exact path="/notificari" element={<AdminRoute><Notifications /></AdminRoute>} />
              <Route exact path="/parteneri" element={<AdminRoute><Partners /></AdminRoute>} />
              <Route exact path="/speakeri" element={<AdminRoute><Speakers /></AdminRoute>} />

              // user role paths
              <Route exact path="/evenimentele-mele" element={<MyEvents />} />
              <Route exact path="/profil-utilizator" element={<Profile />} />


              // website path
              <Route exact path="/event/:companySlug/:slug/*" element={<EventWebsite />} />
              <Route exact path="/checkin/:companySlug/:slug" element={<UserCheckIn />} />

              {/* Other routes */}
            </Routes>
          </Router>
          <ToastContainer  autoClose={1000} />
          </Suspense>
        </ConfigProvider>
      </div>
  );
}

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  return loginService.loggedIn() ? (
    loginService.isAdmin() ? (
      children
    ) : (
      <Navigate
        to={{
          pathname: '/evenimentele-mele',
        }}
      />
    )
  ) : (
    <Navigate
      to={{
        pathname: '/login',
      }}
    />
  )
};

export default App;
